import { Component, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { FormModel } from '@ov-suite/models-shared';
import { FormioComponent } from '@ov-suite/helpers-shared';

interface ViewComponent {
  value: string;
  input: boolean;
  tooltip: string;
}

@Component({
  selector: 'ov-suite-form-keys',
  template: `
    <div class="main-content">
      <div *ngFor="let form of details" style="display: flex; padding: 5px;">
        <div>
          <b>{{ form.key }}</b>
          <span> - </span>
          <ng-container *ngFor="let component of form.components">
            <ng-template #tipContent>{{ component.tooltip }}</ng-template>
            <span
              style="padding: 4px; margin: 4px;"
              [ngbTooltip]="tipContent"
              [ngClass]="{ input: component.input, 'not-input': !component.input }"
            >
              {{ component.value }}</span
            >
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .input {
        border: 1px solid black;
      }
      .not-input {
        border: 1px dashed black;
      }
    `,
  ],
})
export class FormKeysComponent implements OnInit {
  forms: FormModel[] = [];

  details: { key: string; components: ViewComponent[] }[] = [];

  constructor(private readonly ovAutoService: OvAutoService) {}
  ngOnInit() {
    this.ovAutoService
      .list({
        entity: FormModel,
        orderColumn: 'key',
      })
      .then(response => {
        this.details = response.data.map(f => {
          const baseData: Partial<FormioComponent>[] = [];

          const evalComponent = (input: Partial<FormioComponent>) => {
            baseData.push(input);
            Object.entries(input).forEach(([k, v]) => {
              if (k.endsWith('Key')) {
                baseData.push({
                  input: input.input,
                  key: v,
                });
              }
            });
            if (input.type === 'table') {
              input.rows.forEach(row => {
                row.forEach(c => {
                  c.components.forEach(evalComponent);
                });
              });
            }
          };

          f.json.components.forEach(evalComponent);

          return {
            key: f.key,
            components: baseData.map(c => this.getDetail(c)) ?? [],
          };
        });
      });
  }

  getDetail(input: Partial<FormioComponent>): ViewComponent {
    if (input.type === 'table') {
    }
    return { value: input.key, input: input.input, tooltip: this.getTooltip(input) };
  }

  getTooltip(input: Partial<FormioComponent>) {
    return JSON.stringify(input, null, 2);
  }
}
