import { Routes } from '@angular/router';
import { FormAddOrEditComponent } from './add-or-edit/form-add-or-edit.component';
import { FormKeysComponent } from './keys/form-keys.component';

export const FormRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: FormAddOrEditComponent,
      },
      {
        path: 'keys',
        component: FormKeysComponent,
      },
    ],
  },
];
