import { NgModule } from '@angular/core';
import { AdminLayoutModule, GraphQLModule, UiModule } from '@ov-suite/ui';
import { AgmCoreModule } from '@agm/core';
import { environment } from '@ov-suite/helpers-shared';
import { JwtInterceptor } from '@ov-suite/authguard-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DomainServiceInitializer, SharedModule } from '@ov-suite/helpers-angular';
import { ErrorViewComponent } from './error-handler/view/error-view.component';
import { ErrorService } from './error-handler/error-handler';
import { AppComponent } from './app.component';
import { angularRoutes, sidebarRoutes } from './app.routing';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [AppComponent, ErrorViewComponent],
  imports: [
    AdminLayoutModule.forRoot({
      projectName: 'Admin Link',
      sidebarRoutes,
      angularRoutes,
    }),
    UiModule.forRoot({
      apiUrl: environment.apiUrl,
      imageUploadUrl: environment.other.awsFileServiceApi,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.other.mapApiKey,
      libraries: ['places'],
    }),
    SharedModule,
    GraphQLModule,
  ],
  providers: [
    ErrorService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: 'DEFAULT_API',
      useValue: 'adminlink',
    },
    DomainServiceInitializer,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
