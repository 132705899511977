import { NgModule } from '@angular/core';
import { FormAddOrEditComponent } from './add-or-edit/form-add-or-edit.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { RouterModule } from '@angular/router';
import { FormRouting } from './form.routing';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { CommonModule } from '@angular/common';
import { DisplayLineComponent } from './add-or-edit/display-line/display-line.component';
import { FormKeysComponent } from './keys/form-keys.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [NgJsonEditorModule, RouterModule.forChild(FormRouting), FormsModule, NgbModule, UiModule, CommonModule],
  declarations: [FormAddOrEditComponent, DisplayLineComponent, FormKeysComponent],
})
export class FormModule {}
