import { Component, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { FormioModel } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-form-add-or-edit',
  templateUrl: './form-add-or-edit.component.html',
  styleUrls: ['./form-add-or-edit.component.css'],
})
export class FormAddOrEditComponent {
  public data: Partial<FormioModel>;

  constructor() {
    // this.editorOptions = new JsonEditorOptions();
    // this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    //this.options.mode = 'code'; //set only one mode

    this.data = {
      components: [
        {
          type: 'textfield',
          key: 'test',
          validate: {
            required: true,
          },
        },
      ],
    };
  }

  getEntries(input: object): [string, unknown][] {
    return Object.entries(input);
  }

  isObject(input: unknown): boolean {
    return typeof input === 'object';
  }
}
