<!--<json-editor [options]="editorOptions" [data]="data"></json-editor>-->

<div class="main-content">
  <div *ngIf="!!data">
    <div *ngFor="let component of data.components">
      <div style="border: 1px solid black">
        <ov-suite-display-line *ngFor="let inner of getEntries(component)" [key]="inner[0]" [value]="inner[1]"></ov-suite-display-line>
        <!--        <div *ngFor="let obj of getEntries(component)">-->
        <!--          {{ rec }}-->
        <!--          <ng-template #rec *ngIf="isObject(obj[1]); else display"></ng-template>-->
        <!--          <ng-template #display><div>{{obj[0]}} : {{obj[1]}}</div></ng-template>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</div>
