import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { QueryParam } from '@ov-suite/helpers-shared';

@Component({
  template: `
    <div class="toggle-row">
      <span>Storage Unit</span>
      <input class="ov-checkbox" type="checkbox" [checked]="storageUnit" (change)="onToggleStorageUnit()" />
    </div>
    <div class="toggle-row">
      <span>Sales Unit</span>
      <input class="ov-checkbox" type="checkbox" [checked]="salesUnit" (change)="onToggleSalesUnit()" />
    </div>
  `,
  styleUrls: ['./unit-toggles.component.scss'],
})
export class UnitTogglesComponent {
  salesUnit = false;
  storageUnit = false;

  constructor(private router: Router, private route: ActivatedRoute) {
    route.queryParamMap.subscribe(paramMap => {
      this.salesUnit = paramMap.get(QueryParam.query + ':salesUnit') === 'true';
      this.storageUnit = paramMap.get(QueryParam.query + ':storageUnit') === 'true';
    });
  }

  onToggleStorageUnit() {
    this.storageUnit = !this.storageUnit;
    if (this.storageUnit) {
      this.setParams({
        [`${QueryParam.query}:storageUnit`]: 'true',
      });
    } else {
      this.setParams({
        [`${QueryParam.query}:storageUnit`]: null,
      });
    }
  }

  onToggleSalesUnit() {
    this.salesUnit = !this.salesUnit;
    if (this.salesUnit) {
      this.setParams({
        [`${QueryParam.query}:salesUnit`]: 'true',
      });
    } else {
      this.setParams({
        [`${QueryParam.query}:salesUnit`]: null,
      });
    }
  }

  setParams(param: Record<string, string | number>) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...param },
      skipLocationChange: false,
      replaceUrl: true,
      queryParamsHandling: 'merge',
    } as NavigationExtras);
  }
}
