import { Component, Input } from '@angular/core';

@Component({
  selector: 'ov-suite-display-line',
  template: `
    <div>
      <div #rec *ngIf="isObject(value); else display" style="display: flex;">
        <div>{{ key }} :</div>
        <div style="border: 1px solid black; flex: 1">
          <ov-suite-display-line *ngFor="let inner of getEntries(value)" [key]="inner[0]" [value]="inner[1]"></ov-suite-display-line>
        </div>
      </div>
      <ng-template #display
        ><div>{{ key }} : {{ value }}</div></ng-template
      >
    </div>
  `,
})
export class DisplayLineComponent {
  @Input() key: string;
  @Input() value: unknown;

  getEntries(input: object): [string, unknown][] {
    return Object.entries(input);
  }

  isObject(input: unknown): boolean {
    return typeof input === 'object';
  }
}
